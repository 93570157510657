import { useEffect, useState } from "react";
import aituBridge from "@btsd/aitu-bridge";
import {
  IonApp,
  IonContent,
  IonButton,
  IonModal
} from "@ionic/react";

import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import * as $ from 'jquery';

/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {
  async function getMe() {
    try {
      const data = await aituBridge.getMe();
      setName(data.name);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (aituBridge.isSupported()) {
      getMe();
    }
  }, []);

  const [name, setName] = useState("");

  const randomGrid = (val: number) => {
    newRandomGrid(val);
    setShowLevelSelectionModal(false);
  }

  const openRestartModal = () => {
    setShowRestartModal(true);
    setShowLevelSelectionModal(false);
  }

  const newGrid = () => {
    setShowRestartModal(false);
    setShowLevelSelectionModal(true);
  }

  const doRestart = () => {
    restart();
    setShowRestartModal(false);
  }

  const cancelRestart = () => {
    setShowRestartModal(false);
  }

  const showWrongAnswers = () => {
    check();
  }

  const solveCurrentGrid = () => {
    solve();
  }

  useEffect(() => {
    init();
  }, []);

  const [showRestartModal, setShowRestartModal] = useState(false);
  const [showLevelSelectionModal, setShowLevelSelectionModal] = useState(false);

  return (
    <IonApp>
      <IonContent>
      
      <IonModal
          isOpen={showRestartModal}
          cssClass='restart-confirmation-modal'
          swipeToClose={true}
          onDidDismiss={() => setShowRestartModal(false)}>
          <h1>Перезапустить игру?</h1>
          <IonButton
              onClick={() => doRestart()}
            >
              Да
            </IonButton>
            <IonButton
              onClick={() => cancelRestart()}
            >
              Нет
            </IonButton>
        </IonModal>

        <IonModal
          isOpen={showLevelSelectionModal}
          cssClass='new-game-modal'
          swipeToClose={true}
          onDidDismiss={() => setShowLevelSelectionModal(false)}>
          <h1>Выберите сложность</h1>
          <IonButton
              onClick={() => randomGrid(72)}
            >
              Легко
          </IonButton>
          <IonButton
              onClick={() => randomGrid(96)}
            >
              Средне
          </IonButton>
          <IonButton
              onClick={() => randomGrid(128)}
            >
              Сложно
          </IonButton>
          <IonButton
              onClick={() => randomGrid(192)}
            >
              Сумасшедше
          </IonButton>
        </IonModal>

        <div data-role="page" id="home">

          <header>
            <img src="../../assets/logo.svg" alt="" />
            {name &&
              <h2>
                <span>Привет, {name}</span>
              </h2>
            }
          </header>
          <div role="main" className="ui-content">
            <div className="table">
              <table id="grid"></table>
            </div>

            <div className="table panel">
              <table id="digits">
                  <tbody>
                    <tr>
                        <td id="digit-0" className="digit"></td>
                        <td id="digit-1" className="digit">1</td>
                        <td id="digit-2" className="digit">2</td>
                        <td id="digit-3" className="digit">3</td>
                        <td id="digit-4" className="digit">4</td>
                    </tr>
                    <tr>
                        <td id="digit-5" className="digit">5</td>
                        <td id="digit-6" className="digit">6</td>
                        <td id="digit-7" className="digit">7</td>
                        <td id="digit-8" className="digit">8</td>
                        <td id="digit-9" className="digit">9</td>
                    </tr>
                  </tbody>
              </table>
            </div>
            
            <IonButton
              onClick={() => openRestartModal()}
            >
              Перезапустить
            </IonButton>
            <IonButton
              onClick={() => newGrid()}
            >
              Новая игра
            </IonButton>
            <IonButton
              onClick={() => solveCurrentGrid()}
            >
              Показать решение
            </IonButton>
            <IonButton
              onClick={() => showWrongAnswers()}
            >
              Показать ошибки
            </IonButton>
          </div>

          <div data-role="popup" id ="waiting" className="ui-content" data-theme="b" data-overlay-theme="b">
            <p>Создаем новую таблицу</p>
          </div>

        </div>
      </IonContent>
    </IonApp>
  );
};

export default App;
